import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import { Container } from 'react-bootstrap';

function Body() {
    return (
        <div>
          <ScrollAnimation duration={2}
                    animateIn='zoomIn' initiallyVisible={true}
                    animateOnce={true}
                ><h1 className="sora-bold center-x" >A Quick Tour of our Campus</h1></ScrollAnimation>
                <video className="w-100 " src="./assets/tour.m4v" loop muted autoPlay></video>
           <Container>
               <div className="innerContainer">

               <ScrollAnimation animateIn='fadeIn' initiallyVisible={true} duration={2}
  animateOnce={true}
  ><h1 className="sora-green">Curriculum Overview</h1></ScrollAnimation>
<ScrollAnimation animateIn='bounceInRight' initiallyVisible={true}
  animateOnce={true}
  >
<p>K-Bols International School provides a comprehensive curriculum, emphasizing the sciences, art, and Religion.</p>
<p>K-Bols classes begin at 7:30 a.m. and ends at 2:00 p.m. Eight periods are devoted to academic coursework and one
     period is designated for student activities and activity-related coursework. Student and faculty research is 
     supported by the Computer Lab and E-Library. Many online resources are made available to students and staff, to aid easy Learning.</p>

     <p>The foundation of the curricular program at K-Bols focuses on an interdisciplinary approach aimed at maximizing the full potential of each student's intellectual and affective skills. K-Bols has a Nigerian-British curriculum, Therefore the proficency in the use of the English Language and Diction is taken very seriously. The Junior students also offer History to grow their roots in the differents of Nigeria and the world. </p>
</ScrollAnimation></div>
           </Container>
        </div>
    )
}

export default Body
