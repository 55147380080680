import React from 'react'
import { Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

function Missing() {
    return (
        
    
   <div><Container className='center'>
       <img className='center-x' src="./assets/logo.png" alt="" />
       <p className='pageMargin'></p>
       <img className='center-x' src="./assets/404.svg" alt="" />
       
   <span className=' center-x'>
   <a href="/" ><button className='btntransparent'>Go back home</button></a></span>
   
   
   </Container>
   </div>
    )

}

export default Missing