import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container} from 'react-bootstrap';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

function Admission() {
    
    return (
        <div id="admission">
            <ScrollAnimation animateIn='fadeIn'animateOnce={true}
  ><h2 className="sora-green text-center">ADMISSION PROCESS</h2>
            <span className="center-x">
                <img className="w-100 img-414" src="./assets/admission.png" alt="" />
            </span>
            <span ></span>
            <Container className="deck">
                <div className="admBox">
                    <div className="admWidth">
                    <h5 className="poppins-bold">Download Form</h5>
                    <p className="poppins">
                        Click the link below to download the application form.
                    </p>
                    <a href="./assets/kbols_form.pdf" download><button  className= "btntransparent" >DOWNLOAD</button>
                    </a><h1 className="poppins" style={{color: "white", marginTop:"100px", marginLeft:"240px"}}>
                        1
                    </h1>
                    </div>

                </div>
                <div className="admBox">
                <div className="admWidth">
                    <h5 className="poppins-bold">Apply</h5>
                    <p className="poppins">
                    Print and submit the completed application in person or email to <a href="mailto:kbolsltd@gmail.com">kbolsltd@gmail.com</a>.
                    </p>
                    <a href="mailto:kbolsltd@gmail.com"><button  className= "btntransparent" >SUBMIT</button></a>
                    <h1 className="poppins" style={{color: "white", marginTop:"75px", marginLeft:"240px"}}>
                        2
                    </h1>
                    </div>
                </div>
                <div className="admBox">
                <div className="admWidth">
                    <h5 className="poppins-bold">Feedback</h5>
                    <p className="poppins">
                    An email will be sent to your email to confirm the status of your admission. you can also contact us.
                    </p>
                    <a href="/contact"><button  className= "btntransparent" >CONTACT</button></a>
                    <h1 className="poppins" style={{color: "white", marginTop:"55px", marginLeft:"240px"}}>
                        3
                    </h1>
                    </div>
                </div>

            </Container>
            </ScrollAnimation>
        </div>
    )
}

export default Admission;
