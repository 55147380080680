import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { Carousel } from 'react-bootstrap';
import styled from "styled-components"

const DIV = styled.div`
    /* ARTIST PICTURE */
        margin: 0 auto;
        margin-top: 30px;
        max-width: 80%;
        columns: 3;
        column-gap: 1px;
        text-align: center;
        transition: all linear 0.2s;

    .img-border{
        max-width: 98%;
        transition: all linear 0.2s;
        margin-bottom: 5px;
        border: 5px solid white;
    }
    @media(max-width: 700px){
        columns: 2;
        column-gap: 1px;
        text-align: center;
        transition: all linear 0.2s;

        {
            margin-bottom: 2px;
            max-width: 98%;
            transition: all linear 0.2s;
        }
    }
    /* ARTIST PICTURE */

`

function Body() {
    return (
        <div>
            <div className="tour">
                <ScrollAnimation duration={1.5}
                    animateIn='zoomIn' initiallyVisible={true}
                    animateOnce={true}
                ><h1 className="sora-bold center-x " >A Quick Tour of our Campus</h1></ScrollAnimation>
                <video className="w-100 " src="./assets/tour.m4v" loop muted autoPlay></video></div>
            {/* facilities carousel */}

            <div className="gal-shade-1">


                <Carousel>
                    <Carousel.Item >
                        <Carousel.Caption>
                            <div className="carousel-text">
                                <h3>The Hostel</h3>
                                <p>It can capacitate for 700 students comfortably.</p>
                            </div>
                        </Carousel.Caption>
                        <img
                            className="d-block w-100"
                            src="./assets/hall.png"
                            alt="Second slide"
                        />

                    </Carousel.Item>


                    <Carousel.Item >
                        <Carousel.Caption>
                            <div className="carousel-text">
                                <h3>The room</h3>
                                <p>An air-conditioned and spacious room with a maximum of 20 students in each room.</p>
                            </div>
                        </Carousel.Caption>
                        <img
                            className="d-block w-100"
                            src="./assets/hall1.png"
                            alt="Second slide"
                        />

                    </Carousel.Item>


                    <Carousel.Item >
                        <Carousel.Caption>
                            <div className="carousel-text">
                                <h3>The Dinning Hall</h3>
                                <p>It has space to take in 1500 people at once.</p>
                            </div>
                        </Carousel.Caption>
                        <img
                            className="d-block w-100"
                            src="./assets/dinning.png"
                            alt="Second slide"
                        />

                    </Carousel.Item>


                    <Carousel.Item >
                        <Carousel.Caption>
                            <div className="carousel-text">
                                <h3>The Dinning Hall</h3>
                                <p>A view of one-half of our dinning hall.</p>
                            </div>
                        </Carousel.Caption>
                        <img
                            className="d-block w-100"
                            src="./assets/dinning1.png"
                            alt="Second slide"
                        />

                    </Carousel.Item>


                    <Carousel.Item >
                        <Carousel.Caption>

                        </Carousel.Caption>
                        <img
                            className="d-block w-100"
                            src="./assets/court.png"
                            alt="Second slide"
                        />

                    </Carousel.Item>


                </Carousel>

            </div>
            <div className="gal-shade-2">
                <ScrollAnimation duration={1.5}
                    animateIn='zoomIn' initiallyVisible={true}
                    animateOnce={true}
                ><h1 className="sora-bold center-x white">#A trip to Accra, Ghana (2019)</h1></ScrollAnimation> <DIV ><ScrollAnimation duration={1.5}
                animateIn='fadeIn'>
                    <img className="img-border" src="./assets/gh1.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh2.jpg" width="500px" alt="" />
                    <img className="img-border" src="./assets/gh3.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh4.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh5.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh6.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh7.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh8.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh10.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh9.jpg" alt="" width="500px" />
                    </ScrollAnimation>
                </DIV>
            </div>
            <div className="gal-shade-3"> <ScrollAnimation duration={1.5}
                    animateIn='zoomIn' initiallyVisible={true}
                    animateOnce={true}
                ><h1 className="sora-bold center-x white">Our Campus</h1></ScrollAnimation> <DIV><ScrollAnimation duration={1.5}
                animateIn='fadeIn'>
            <img className="img-border" src="./assets/sc1.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc3.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc2.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc4.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc6.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc5.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc7.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc13.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc10.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc9.jpg" alt="" width="500px" />
            </ScrollAnimation>
            </DIV>
</div>



<div className="gal-shade-5">
                <ScrollAnimation duration={1.5}
                    animateIn='zoomIn' initiallyVisible={true}
                    animateOnce={true}
                ><h1 className="sora-bold center-x white">Our Students</h1></ScrollAnimation> <DIV ><ScrollAnimation duration={1.5}
                animateIn='fadeIn'>
                    <img className="img-border" src="./assets/pan1.jpg" alt="" width="500px" />           
                    <img className="img-border" src="./assets/pan2.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh2.jpg" width="500px" alt="" />
                    <img className="img-border" src="./assets/stu1.png" alt="" width="500px" />
                    <img className="img-border" src="./assets/pan5.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/stu3.png" alt="" width="500px" />
                    <img className="img-border" src="./assets/pan3.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/stu3.png" alt="" width="500px" />
                    <img className="img-border" src="./assets/gh10.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/sc1.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/stu5.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/stu4.png" alt="" width="500px" />
                    <img className="img-border" src="./assets/stu6.jpg" alt="" width="500px" />
                    <img className="img-border" src="./assets/stu7.jpg" alt="" width="500px" />
                    </ScrollAnimation>
                </DIV>
            </div>

<div className="gal-shade-4"> <ScrollAnimation duration={1.5}
                    animateIn='zoomIn' initiallyVisible={true}
                    animateOnce={true}
                ><h1 className="sora-bold center-x white">More on our campus</h1></ScrollAnimation>
    <DIV><ScrollAnimation duration={1.5}
                    animateIn='fadeIn'>
    <img className="img-border" src="./assets/sc14.jpg" alt="" width="500px" />
    <img className="img-border" src="./assets/sc15.jpg" alt="" width="500px" />
    <img className="img-border" src="./assets/sc16.jpg" alt="" width="500px" />
    <img className="img-border" src="./assets/sc17.jpg" alt="" width="500px" />
    <img className="img-border" src="./assets/sc18.jpg" alt="" width="500px" />
    <img className="img-border" src="./assets/sc11.jpg" alt="" width="500px" />
            <img className="img-border" src="./assets/sc8.jpg" alt="" width="500px" />
            </ScrollAnimation>
    </DIV>
</div>

<img className="w-100" src="./assets/boarding.jpg" alt="" />

        </div>
    )
}

export default Body
