import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
function Intro() {

    const capStyle = {
        fontSize: "14px",
        paddingTop: "20px",
    }

    return (

        <div>
            <Container>

                <span className="deck" ></span>
                <Row >
                    <Col>


                        <div className="intro-length" ><ScrollAnimation duration={1.5} animateIn='fadeIn' initiallyVisible={true}
                            animateOnce={true}
                        ><h2 className="sora-green">WELCOME ON BOARD</h2></ScrollAnimation>
                            <ScrollAnimation duration={1.5} animateIn='bounceInLeft' animateOnce={true}>
                                <div className="content">
                                    <p> <b>K-Bols International School</b> is a co-educational and full boarding school
                                        of international standard. It is primarily conceived to train young Nigerians and other nationals to attain their full potential and develop the high level of professionalism needed to perform excellently in their future careers and to be productive and responsible global citizen.
                                        By providing world class facilities, we are sure to bring out the best potential in our students.</p>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </Col>
                    <Col class="col mr-auto">
                        <ScrollAnimation duration={1.5} animateIn='bounceInRight' animateOnce={true} >
                            <div><img className="w-100 img-414" src="./assets/welcome.png" alt="" /></div></ScrollAnimation>
                    </Col>
                </Row>
                <span className="deck" ></span>

                <Row >
                    <Col >

                        <div className="intro-length" ><ScrollAnimation duration={1.5} animateIn='fadeIn' initiallyVisible={true}
                            animateOnce={true}
                            animateOut='fadeOut'><h2 className="sora-green">LEARNING WITH GOOGLE</h2></ScrollAnimation>
                            <ScrollAnimation duration={1.5} animateIn='bounceInLeft'animateOnce={true} > <div className="content">
                                <p>AS the popular axiom goes "knowledge is power", In our ever evolving world our students cant't be left behind, our students take certified courses with google to gain beneficial skills;
                                    learning goes beyond the four walls of a classroom. 
                                </p>
                            </div></ScrollAnimation>
                        </div>
                    </Col>
                    <Col><ScrollAnimation duration={2} animateIn='bounceInRight' animateOnce={true}>
                        <div><img className="w-100 img-414" src="./assets/google_illustration.png" width="500px" alt="" /></div></ScrollAnimation>
                    </Col>
                </Row>
                <span className="deck" ></span>

                <Row >
                    <Col >
                        <div className="intro-length" ><ScrollAnimation duration={1.5} animateIn='fadeIn' initiallyVisible={true}
                            animateOnce={true}
                        ><h2 className="sora-green">STATISTICS</h2></ScrollAnimation>
                            <ScrollAnimation duration={2} animateIn='bounceInLeft' animateOnce={true}>
                                <div className="content">
                                    <p>Our students perform better academically because they live in an environment
                                        that is designed for  conducive learning, which is their primary purpose of being in secondary school.
                                        Our Boarding School prepares students uniquely for university life. Our graduates have
                                        all ended up gaining admissions into the best and finest universities of their choice and We glad to have graduates both overseas and at home.
                                    </p>
                                </div></ScrollAnimation>
                        </div>
                    </Col>
                    <Col>

                        <div className="intro-length" ><ScrollAnimation duration={1.5} animateIn='fadeIn' initiallyVisible={true}
                            animateOnce={true}
                        ><h2 className="sora-green">TESTIMONIAL</h2></ScrollAnimation>
                            <div className="content">
                                <ScrollAnimation duration={2} animateIn='bounceInRight' animateOnce={true}>
                                    <Carousel variant="dark">

                                        <Carousel.Item interval={5000}>
                                            <div className="testimonialBox img-414">
                                                <Carousel.Caption style={capStyle}>
                                                    <h4>Mrs Zainab</h4>
                                                    <p>“Its a home away from home experience. My Child has really Improved”
                                                    </p>
                                                </Carousel.Caption>
                                            </div>
                                        </Carousel.Item>

                                        <Carousel.Item interval={10000}>
                                            <div className="testimonialBox">
                                                <Carousel.Caption style={capStyle}>
                                                    <h4>Uyime Umoren</h4>
                                                    <p>“I walked into K-Bols as an average student and after being tutored by the best
                                                        I finished with  distinctions and I gained
                                                        my admission into the University at my first attempt ,thanks to K-Bols.”
                                                    </p>
                                                </Carousel.Caption>
                                            </div>
                                        </Carousel.Item>

                                        <Carousel.Item interval={5000}>
                                            <div className="testimonialBox">
                                                <Carousel.Caption style={capStyle}>
                                                    <h4>Student</h4>
                                                    <p>“I started off as an intovert, now I am a great public speaker... I can only thanks K-Bols”
                                                    </p>
                                                </Carousel.Caption>
                                            </div>
                                        </Carousel.Item>

                                    </Carousel>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </Col>
                </Row>



            </Container>
        </div>
    )
}

export default Intro
