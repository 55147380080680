import React from 'react'
import { Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Footer from './Footer';
import Barnav from './Barnav';

function Policy() {
    return (
        <div>
            <Barnav/>
            <div className="sections"><Container>
            <div style={{ marginBottom:"60px"}}></div>
<img className="w-100 img-policy center-x" src="./assets/welcome.png" alt="" />
<h3 className="sora-green">Data Protection Policy</h3>

<p>K-Bols International School collects and uses personal information about staff, students, parents and other individuals who come into contact with the school. This information is gathered in order to enable the school to provide education and other associated functions. In addition, there may be a legal requirement to collect and use information to ensure that the school complies with its statutory obligations.</p>
<p>Schools have a duty to issue a Fair Processing Notice to all students/parents, this summarises the information held on students, why it is held and the other parties to whom it may be passed on.</p>
<h4 className="sora-green">Purpose</h4>
<p>This policy is intended to ensure that personal information is dealt with correctly and securely and in accordance with the Data Protection Act 1998, and other related legislation. It will apply to information regardless of the way it is collected, used, recorded, stored and destroyed, and irrespective of whether it is held in paper files or electronically.</p>
<p>All staff involved with the collection, processing and disclosure of personal data will be aware of their duties and responsibilities by adhering to these guidelines.</p>
<h4 className="sora-green">Personal Information?</h4>
<p >Personal information or data is defined as data which relates to a living individual who can be identified from that data, or other information held.</p>
<h4 className="sora-green">Data Protection Principles</h4>
<p>
The Data Protection Act 1998 establishes eight enforceable principles that must be adhered to at all times:
</p>
<ol>
<li>Personal data shall be processed fairly and lawfully;</li>
<li>Personal data shall be obtained only for one or more specified and lawful purpose(s);</li>
<li>Personal data shall be adequate, relevant and not excessive;</li>
<li>Personal data shall be accurate and where necessary, kept up to date;</li>
<li>Personal data processed for any purpose shall not be kept for longer than is necessary for that purpose or those purposes;</li>
<li>Personal data shall be processed in accordance with the rights of data subjects under the Data Protection Act 1998;</li>
<li>Personal data shall be kept secure i.e. protected by an appropriate degree of security;</li>
<li>Personal data shall not be transferred to a country or territory outside the European Economic Area, unless that country or territory ensures an adequate level of data protection.
</li></ol>
<h4 className="sora-green">General Statement</h4>
<p>The school is committed to maintaining the above principles at all times. Therefore the school will:</p>
<ul>
<li> Inform individuals why the information is being collected when it is collected</li>
<li> Inform individuals when their information is shared, and why and with whom it was shared</li>
<li> Check the quality and the accuracy of the information it holds</li>
<li> Ensure that information is not retained for longer than is necessary</li>
<li> Ensure that when obsolete information is destroyed that it is done so appropriately and securely</li>
<li> Ensure that clear and robust safeguards are in place to protect personal information from loss, theft and unauthorised disclosure, irrespective of the format in which it is recorded</li>
<li> Share information with others only when it is legally appropriate to do so</li>
<li> Set out procedures to ensure compliance with the duty to respond to requests for access to personal information, known as Subject Access Requests</li>
<li> Ensure our staff are aware of and understand our policies and procedures 
</li></ul>
<h4 className="sora-green">Complaints</h4>
<p>Complaints will be dealt with in accordance with the school’s complaints policy. Complaints relating to information handling may be referred to the Information Commissioner (the statutory regulator). </p>

<div style={{ marginBottom:"60px"}}></div>
    </Container>
           <Footer/> </div>
        
        
        </div>
    )
}

export default Policy
