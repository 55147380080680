import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import emailjs from 'emailjs-com';
import swal from 'sweetalert';

function Body() {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_szghm97', 'template_5kqvyqn', e.target, 'user_ZRBwCnSmaxoVUX2kDnK5p')
            .then((result) => {
                swal({
                    title: "Delivered",
                    text: "we will get back to you as soon as possible",
                    icon: "success",
                  });

            }, (error) => {
                console.log(error.text);
            });

        document.getElementById('contact-form').reset();
    }
    return (
        <div>
            <Container>
                <Row>
                    
                    <Col className="contact">
                       <ScrollAnimation animateIn='bounceInLeft' initiallyVisible={true}
  animateOnce={true}> 
                        
                            <h3 className="sora-green">Send us a message.</h3>


                            <Form className="contact " id="contact-form" onSubmit={sendEmail}>
                                <Form.Group className="mb-3" controlId="formBasicText">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter name here" style={{ border: "#ED7200 3px solid" }} name="fullname" id="fullname" required/>
                                </Form.Group>

                                <Row>
                                    <Col> <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="user123@kbols.com" style={{ border: "#ED7200 3px solid" }} name="mail" id="mail" required/>
                                    </Form.Group>
                                    </Col>

                                    <Col><Form.Group className="mb-3" controlId="formBasicTel">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="tel" placeholder="0801 234 5678" style={{ border: "#ED7200 3px solid" }} name="contact" id="contact" required/>
                                    </Form.Group>
                                    </Col>
                                </Row>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" placeholder="Hi, I would like to..."  style={{ border: "#ED7200 3px solid" , height: "150px" }} name="message" id="message" required/>
                                </Form.Group>
                                <button type="submit">
                                    Submit
                                </button> <div style={{ marginBottom: "20px" }}></div>
                            </Form>
                          </ScrollAnimation>
                    </Col>

                    <Col>
                    <ScrollAnimation animateIn='bounceInRight' initiallyVisible={true}
  animateOnce={true}>
                            <iframe className="w-100 img-414" title="map" width="500" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=K-Bols%20International%20School,%20WMH6+26P,%20961104,%20New%20Nyanya&t=k&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                    </ScrollAnimation></Col>
                </Row>
                <img className="w-100" src="./assets/boarding.jpg" alt="" />
            </Container>
        </div>
    )
}

export default Body
