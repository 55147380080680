import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Barnav from './Barnav'
import { init } from 'ityped'
import News from './News';
import Footer from './Footer';
import Body from './gallery/Body';
function Gallery() {
    const [active , setActive ] = useState("gallery")
    const textRef = useRef();

    useEffect(()=>{
      init(textRef.current,{
          showCursor: true,
          backDelay: 1500,//time taken before erasing text
          backSpeed: 100,// type back speed
          strings: [ "School.", "Dinning hall.", "Games court.", "Swimming pool.", "laboratories." , "Library."]
      })
    },[])
    return (
        <div className="gallery"> 
            <Barnav activePage= {active} setActivePage={setActive} />
            <div className="sections" >
                <div className="noticeBox">
<Container>
    <Row>
        <Col><h1 className="sora-bold" style={{fontSize:"500%"}}>Our Gallery</h1>
        <h3 className="sora">Take a look at our <span style={{color:"white"}} ref={textRef}></span></h3></Col>
        <Col><img className=" w-100 img-414" src="./assets/gal-header.png" width="700px" alt="" /></Col>
        </Row>
        </Container>
        
        
                </div>
                <Body/>
                <News/>
            <Footer/>
            </div>
            
        
        </div>
        
    )
}

export default Gallery
