import React,{ useState} from 'react';
import Slide from "./home/Slide";
//import Maxcontainer from "./component/Maxcontainer";
import Intro from "./home/Intro";
import 'bootstrap/dist/css/bootstrap.min.css'
//import Noticeboard from './home/Noticeboard';
import Owlcarousel from './home/Owlcarousel';
import Admission from './home/Admission';
import Footer from './Footer';
import Barnav from './Barnav';
import News from './News';
import Feed from './home/Feed';

function Home() {
    const [active, setActive] = useState("home")
  return (
    <div > 
      <script type="text/javascript"  src="https://apiv2.popupsmart.com/api/Bundle/401435" async></script>
    <Barnav activePage= {active} setActivePage={setActive}/> 
    <div className="sections">
   <Slide/>
     
     <div style={{ marginBottom:"60px"}}></div>
     <Intro/>
     <div style={{ marginBottom:"90px"}}></div>
     <Owlcarousel/>
     {/*<div style={{ marginBottom:"90px"}}></div>
    <Noticeboard/>*/}
     <div style={{ marginBottom:"90px"}}></div>
     <Admission/>
     <div style={{ marginBottom:"90px"}}></div>
     <Feed/>
     <div style={{ marginBottom:"90px"}}></div>
     <News/>
     <Footer/></div>
    </div>
  );
}
//{barnav}>>menu bar
//{slide}>> picture carousels
export default Home;
