
import Barnav from "./Barnav";
import Footer from './Footer';
import Body from "./about-founder/Body";
import Header from "./about-founder/Header";
import React, { useState }  from 'react';
import News from "./News";
function About_founder() {
    const [active , setActive ] = useState("about")
    return (
        <div>
            <Barnav activePage= {active} setActivePage={setActive}/>
         <div className="sections">
            <Header/>
            <div style={{ marginBottom:"60px"}}></div>
            <Body/>
            <div style={{ marginBottom:"60px"}}></div>
            <News/>
            <Footer/>
            </div>
        </div>
    )
}
export default About_founder
