import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './component/Home';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import About from './component/About';
import About_founder from './component/About-founder';
import Academics from './component/Academics';
import Contact from './component/Contact';
import Gallery from './component/Gallery';
import Boarding from './component/Boarding';
import Policy from './component/Policy';
import Missing from './component/Missing';

function App() {
  
  return (
    <div className="app" > 
    
    <BrowserRouter>    
    <Switch>
    <Route path="/" exact component={Home}  />
    <Route path="/about" component={About} />
    <Route path="/about-founder" component={About_founder} />
    <Route path="/academics" component={Academics} />
    <Route path="/contact" component={Contact} />
    <Route path="/gallery" component={Gallery} />
    <Route path="/boarding" component= {Boarding}/> 
    <Route path="/policy" component= {Policy}/>
    <Route path="*" component={Missing} /> 

    </Switch>
    </BrowserRouter>
    </div>
  );
}
//wildcard "*" for page not found
//{barnav}>>menu bar
//{slide}>> picture carousels
export default App;
