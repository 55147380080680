import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import Marquee from "react-fast-marquee";



function Feed() {

    return (
        <div id="center-x">
            <Container  >
                <h2 className="sora-green text-center">Our Feed</h2>
                <p className="poppins text-center">Stay connected with what happens on our social media platforms</p>
                <div style={{ marginBottom:"30px"}}></div>
                <div >

                    <Marquee pauseOnHover={true} pauseOnClick={true} speed={20} gradient={false}>
                        <a className='feedLink' href="https://www.instagram.com/p/C2cCeNVory6/?utm_source=ig_web_button_share_sheet&igsh=MjM0N2Q2NDBjYg==" target="_blank" rel="noopener noreferrer">
                            <div className='feedCard'>
                                <div className="feedboarder">
                                    <img className='w-100 feedimg' src="./assets/love.png" alt="Valentine outreach" />
                                    <h3 className='feedmargin poppins'>An Act of Love</h3>
                                    <p className='poppins'> We will be educating and supporting orphans come February,14. Helping one life at a time</p>
                                    <p className='feedDate'> Jan 7th</p>

                                </div>
                            </div>
                        </a>
                        <a className='feedLink' href="https://www.instagram.com/p/C2fVUHGtr7O/?utm_source=ig_web_copy_link&igsh=MjM0N2Q2NDBjYg==" target="_blank" rel="noopener noreferrer">
                            <div className='feedCard'>
                                <div className="feedboarder">
                                    <img className='w-100 feedimg' src="./assets/educationday.png" alt="women's day" />
                                    <h3 className='feedmargin poppins'>International Education day</h3>
                                    <p className='poppins'>Education is one thing, no one can take  from you.</p>
                                    <p className='feedDate'> January 24th</p>
                                </div>
                            </div>
                        </a>
                        <a className='feedLink' href="https://www.instagram.com/p/C2HpRlqIXcb/?utm_source=ig_web_copy_link&igsh=MjM0N2Q2NDBjYg==" >
                            <div className='feedCard'>
                                <div className="feedboarder">
                                    <img className='w-100 feedimg' src="./assets/armedday.png" alt="Armed forces rememberance day" />
                                    <h3 className='feedmargin poppins'>Armed forces rememberance day</h3>
                                    <p className='poppins'>Our Heroes will never be forgotten!</p>
                                    <p className='feedDate'> Jan 16th</p>
                                </div>
                            </div>
                        </a>
                        <a className='feedLink' href="https://www.instagram.com/reel/C2cDPpeoOFZ/?utm_source=ig_web_copy_link&igsh=MjM0N2Q2NDBjYg==" target="_blank" rel="noopener noreferrer">
                            <div className='feedCard'>
                                <div className="feedboarder">
                                    <img className='w-100 feedimg' src="./assets/ofcourse.png" alt="Best School reel" />
                                    <h3 className='feedmargin poppins'># Of Course Challenge</h3>
                                    <p className='poppins'>Of course your ward is in the best International School, check on Instagram</p>
                                    <p className='feedDate'> January 23th</p>
                                </div>
                            </div>
                        </a>
                        <a className='feedLink' href="#admission" target="_blank" rel="noopener noreferrer">
                            <div className='feedCard'>
                                <div className="feedboarder">
                                    <img className='w-100 feedimg' src="./assets/admission24.png" alt="K-Bols Admission" />
                                    <h3 className='feedmargin poppins'>Admissions</h3>
                                    <p className='poppins'>Ongoing admission from year 7 - 11 (JS1- SS2)</p>
                                    <p className='feedDate'> January 23th</p>
                                </div>
                            </div>
                        </a>

                    </Marquee>


                </div>

            </Container>
        </div>
    )
}

export default Feed;