import React from 'react'
import { Table , Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

function BoardingList() {
    return (
        <div>
            <span className="deck"></span>
            <Container id="boarding-items">

<ScrollAnimation duration={1.5}
                    animateIn='zoomIn'
                ><h1 className="sora-green">Boarding House Items</h1></ScrollAnimation>

            <Table striped bordered hover  variant="success" size="sm">
  <thead>
    <tr>
      <th>#</th>
      <th>BOYS</th>
      <th>QTY</th>
      <th>GIRLS</th>
      <th>QTY</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Blanket</td>
      <td>1</td>
      <td>Blanket</td>
      <td>1</td>
    </tr>
    <tr>
    <td>2</td>
      <td>Bed Sheet</td>
      <td>2</td>
      <td>Bed Sheet</td>
      <td>2</td>
    </tr>
    <tr>
    <td>3</td>
      <td>Black school shoes</td>
      <td>2</td>
      <td>Black school shoes</td>
      <td>2</td>
    </tr>
    <tr>
    <td>4</td>
      <td>sport Shoes</td>
      <td>2</td>
      <td>sport Shoes</td>
      <td>2</td>
    </tr>

    <tr>
    <td>5</td>
      <td>White Socks</td>
      <td>5</td>
      <td>White Socks</td>
      <td>5</td>
    </tr>
    <tr>
    <td>6</td>
      <td>School bag</td>
      <td>1</td>
      <td>School bag</td>
      <td>1</td>
    </tr>
    <tr>
    <td>7</td>
      <td>Plastic Hangers</td>
      <td>12</td>
      <td>Plastic Hangers</td>
      <td>12</td>
    </tr>
    <tr>
    <td>8</td>
      <td>Native Wear</td>
      <td>1</td>
      <td>Native Wear</td>
      <td>1</td>
    </tr>
    <tr>
    <td>9</td>
      <td>Bath Towel</td>
      <td>2</td>
      <td>Bath Towel</td>
      <td>2</td>
    </tr>
    <tr>
    <td>10</td>
      <td>Under wear</td>
      <td>7</td>
      <td>Under wear</td>
      <td>7</td>
     
    </tr> 
    <tr>
    <td>11</td>
      <td>Vest</td>
      <td>5</td>
      <td>Vest(conditional)</td>
      <td>5</td>
      
    </tr>
    <tr>
    <td>12</td>
      <td> </td>
      <td> </td>
      <td>Bra(Conditional)</td>
      <td>5</td>
    </tr>
    <tr>
    <td>13</td>
      <td>handkerchief</td>
      <td>12</td>
      <td>handkerchief</td>
      <td>12</td>
    </tr>
    <tr>
    <td>14</td>
      <td>Shoe brush</td>
      <td>1</td>
      <td>Shoe brush</td>
      <td>1</td>
    </tr>
    <tr>
    <td>15</td>
      <td>Shoe polish (Black)</td>
      <td>2</td>
      <td>Shoe polish (Black)</td>
      <td>2</td>
    </tr><tr>
    <td>16</td>
      <td>Swimming trunk</td>
      <td>1</td>
      <td>Swimming Suit</td>
      <td>1</td>
    </tr>
    <tr>
    <td>17</td>
      <td>Plastic Bucket</td>
      <td>1</td>
      <td>Plastic Bucket</td>
      <td>1</td>
    </tr>
    <tr>
    <td>18</td>
      <td>Small Padlock</td>
      <td>2</td>
      <td>Small Padlock</td>
      <td>2</td>
    </tr>
    <tr>
    <td>19</td>
      <td>Pyjamas</td>
      <td>2</td>
      <td>Night Gowns</td>
      <td>2</td>
    </tr>
    <tr>
    <td>20</td>
      <td>Medical Report</td>
      <td>1</td>
      <td>Medical Report</td>
      <td>1</td>
    </tr>
    <tr>
    <td>21</td>
      <td>Bathroom slippers</td>
      <td>1</td>
      <td>Bathroom slippers</td>
      <td>1</td>
    </tr>
    <tr>
    <td>22</td>
      <td>Swimming Towel</td>
      <td>1</td>
      <td>Swimming Towel</td>
      <td>1</td>
    </tr>
    <tr>
    <td>23</td>
      <td>Toiletries</td>
      <td>1</td>
      <td>Toiletries</td>
      <td>1</td>
    </tr>
    <tr>
    <td>24</td>
      <td>Toilet Paper</td>
      <td>6</td>
      <td>Toilet Paper</td>
      <td>6</td>
    </tr>
    <tr>
    <td>25</td>
      <td>Personal Clipper</td>
      <td>1</td>
      <td>Personal Clipper(Conditional)</td>
      <td>1</td>
    </tr>
    <tr>
    <td>26</td>
      <td>Detergent</td>
      <td> </td>
      <td>Detergent</td>
      <td> </td>
    </tr>
    <tr>
    <td>27</td>
      <td>Pillow</td>
      <td>1</td>
      <td>Pillow</td>
      <td>1</td>
    </tr>
    <tr>
    <td>28</td>
      <td>Nail Cutter</td>
      <td>1</td>
      <td>Nail Cutter</td>
      <td>1</td>
    </tr>
    <tr>
    <td>29</td>
      <td>Face Mask</td>
      <td>50</td>
      <td>Face Mask</td>
      <td>50</td>
    </tr>
  </tbody>
</Table></Container>
        </div>
    )
}

export default BoardingList
