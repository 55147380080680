import React, { useState} from 'react';
import Footer from './Footer';
import Barnav from './Barnav';
import Intro from './contact/Intro';
import Body from './contact/Body';
import News from './News';
function Contact() {
    const [active , setActive ] = useState("contact")
    return (
        <div>
            <Barnav activePage= {active} setActivePage={setActive}/>
            <div className="sections">
            <Intro />
         
         <div style={{ marginBottom:"60px"}}></div>
         <Body/>
         <div style={{ marginBottom:"60px"}}></div>
         <News/>
            <Footer/></div>
        </div>
    )
}

export default Contact
