import React from 'react'
import { Container } from 'react-bootstrap'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

function Body() {
    return (
        <div>
            <Container>
            <ScrollAnimation delay={150} animateIn='fadeIn' initiallyVisible={true}
  animateOnce={true}
  >
            <p> <em>God is faithful</em>  <br /> Education is fundamental to the growth,  advancement, economic and 
                social development of any nation and its people. The most crucial challenge of many developing countries is 
                quality Human Capacity, regardless of the abundance of economic and physical resources. Nigeria is currently 
                at a disadvantage due to poor human capacity (i.e. quality education and human development). There can be no meaningful 
                growth and development without quality human capacity. To address this deficiency, many parents and the government have had 
                to send their sons and daughters overseas to acquire quality education. However, this is becoming more expensive and unaffordable. 
                Quality education in a globalized and highly technical world can only be achieved if primary and secondary education are sound and
                of internationally accepted standards.</p>
                </ScrollAnimation>
                <ScrollAnimation delay={200} animateIn='fadeIn' initiallyVisible={true}
  animateOnce={true}
  >
                <p>Great strides have been made in recent years in primary education in Nigeria by private enterprises especially in the urban cities. However, there is still great deficiency in quality Secondary Education. While there are proliferation of schools called ‘International Secondary Schools’, most are nothing but an attempt to profiteer from the gaping deficiencies in this sector.

K-Bols International School was conceived and structured to address and redress the gross deficiencies in this sector, to ease the financial pains of  parents, the burden of government in funding overseas education and to sustain our national values.

K-Bols is truly of the highest internationally accepted standards (location, facilities and principles). While the school is moulded on Christian faith and values, it caters for boys and girls of all faiths, race and nationalities.
</p>
</ScrollAnimation>
<ScrollAnimation delay={250}  animateIn='fadeIn' initiallyVisible={true}
  animateOnce={true}
  >
<p>
The qualification, experience and background of our teaching staff is paramount to ensuring the desired learning experience and motivation of students. Our teaching staff, is led by a very  experienced principal who was painstakingly selected by the School Governing Board. We have re-trained our teachers to meet our desired standard and professional discipline.

K-Bols concept of Full Boarding is to minimise unnecessary distractions and outside interference to students educational routine. Our Boarding facilities (accommodation, food, laundry and hygiene) has been acclaimed to be of the

best international standard and has gained the commendation of parents and the government educational inspectorate boards.

The School Governing Board has done all that is presently possible to ensure that students have a robust and enhanced knowledge. We will continue to strive to sustain and improve on these standards.

The Principal and Teaching Staff are skilled, well orientated and are provided with the necessary tools to enable them perform efficiently.

The Administrative and School Management is headed by an able and well qualified educationalist of repute.

Thus every boy and girl at K-Bols is assured of a pleasant learning and living experience during his or her time in K-Bols and will leave with a first class educational qualification, enhanced knowledge and full confidence to successfully pursue his or her goal in life with the added help of God.
<br />
Thank you.
</p>
</ScrollAnimation>

                </Container>  
               
        </div>
    )
}

export default Body
