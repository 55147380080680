import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import "animate.css/animate.min.css";

function footer() {
    const footStyle = {
        backgroundColor: "#212529",
        paddingTop: "15px",
        paddingBottom: "15px",
        width: "auto",
    }


    return (
        <div className="">

            <footer class="text-left " style={footStyle}>
                <Container>
                    <Row >

                        <Col >
                            <ScrollAnimation duration={1.5} delay={100} animateIn='flipInX' initiallyVisible={true}
                                animateOnce={true}>
                                <div className="footer-length poppins">
                                    <h1 style={{ color: "#ED7200" }}>Why K-Bols?</h1>
                                    <p style={{ color: "#C1CDC1" }}>
                                        At K-Bols International School we are committed to providing a
                                        comprehensive secondary school education
                                        and moulding the character of young boys and girls who will stand out
                                        among their peers in academics, morals and service to their nation and mankind.
                                    </p>
                                </div>
                            </ScrollAnimation>
                        </Col>

                        <Col>
                            <ScrollAnimation duration={1.5} delay={150} animateIn='flipInX' initiallyVisible={true}
                                animateOnce={true}>
                                <div className="footer-length poppins">
                                    <h1 style={{ color: "#ED7200" }}>Contact us</h1>
                                    <p style={{ color: "#C1CDC1" }}>
                                        <span style={{ color: "#ffffff" }}> Address:</span> Kodape Auta Balefi, Off Abuja - Keffi Expressway, Abuja.</p>

                                    <p style={{ color: "#C1CDC1" }}><span style={{ color: "#ffffff" }}>Number:</span> <a href="tel:+2347031566999">+(234) 703 156 6999</a>, +(234) 908 724 7825 </p>

                                    <p style={{ color: "#C1CDC1" }}><span style={{ color: "#ffffff" }}>Email:</span><a href="mailto:kbolsltd@gmail.com"> kbolsltd@gmail.com</a></p>
                                    
                                    {/*ICONS*/}
                                    <div className="iconsss">

                                        <a href="https://www.facebook.com/KBolsInternationalSchool/" target="_blank" rel="noreferrer">
                                            <FaFacebook size="25px" color="white" />
                                        </a>
                                       

                                        <a href="https://www.instagram.com/kbolsinternational/" target="_blank" rel="noreferrer">
                                            <FaInstagram size="25px" color="white" />
                                        </a>
                                        <a href="https://twitter.com/Kbolsschool" target="_blank" rel="noreferrer">
                                            <FaTwitter size="25px" color="white" />
                                        </a>

                                    </div>
                                    <a style={{ color: "#C1CDC1" }} href="/policy"><i >Data Policy</i></a>
                                </div></ScrollAnimation>
                        </Col>

                        <Col>
                            <ScrollAnimation duration={1.5} delay={200} animateIn='flipInX' initiallyVisible={true}
                                animateOnce={true} >
                                <div className="footer-length poppins">
                                    <h1 style={{ color: "#ED7200" }}>Opening Hours </h1>
                                    <p style={{ color: "#0DA40D" }}>
                                        Monday to Friday 8:00am to 5:00pm <br />
                                        Saturday 10:00am to 2:00pm <br />
                                        Sunday: CLOSED
                                    </p>
                                </div></ScrollAnimation>
                        </Col>
                    </Row>
                    <Row >
                        <p style={{ color: "#ffffff" }}>  © 2024 K-Bols International School- All rights reserved</p>
                        <p style={{ color: "#ffffff" }}>Created by <i style={{ color: "#C1CDC1" }}> <a href="https://www.linkedin.com/in/toluwanimi-tosin-aworekun-665502202"> Tosin-Aworekun Toluwanimi</a></i></p>
                    </Row>
                </Container>
            </footer>

        </div >
    )
}

export default footer
