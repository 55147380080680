import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Col, Container, Row } from 'react-bootstrap';

function Intro() {
    return (
        <div className="about-bg">
            
            <Container className="align-self-center" style={{ marginTop:"auto", marginBottom:"auto"}}>
                <div ><Row  >
                    <Col style= {{ margin: "auto"}} >
                        <div className="intro-length poppins my-auto" style={{ color: "white", paddingTop: "40px" }}>
                            <h3>Who we are</h3>
                            <h1 className="poppins-bold">K-Bols International School</h1>
                            <p> At k-Bols International School we are committed to providing a comprehensive
                                 secondary school education and moulding the character of young boys and girls
                                 who will stand out among their peers in academics, morals and service to their nation and mankind.</p>
                            <br /><p className="text-right">Mrs Akindele Omobolanle;<br/>Co-founder and director of school governing board.</p></div>
                    </Col>
                    <Col>
                        <img className="w-100 img-414" src="./assets/director.png"  alt="" />
                    </Col>
                </Row>
                </div>

            </Container>
        </div>
    )
}

export default Intro
