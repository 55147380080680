import React from 'react'
import { Container, Form } from 'react-bootstrap'
import "animate.css/animate.min.css";

function News() {
    const capstyle = {
        color: "white",
        

    }
    return (
        <div className="news-bg ">
            <Container >
                <div className="news-width">
                    
             <h1 className="sora-green" style={capstyle}>NEWSLETTER SUBSCRIPTION</h1>   
            <Form className="contact validate" id="contact-form mc-embedded-subscribe-form" action="https://gmail.us5.list-manage.com/subscribe/post?u=f585a1124bb6031ba48365801&amp;id=591f4d1286" method="post"  name="mc-embedded-subscribe-form"  target="_blank" novalidate >
        <Form.Group className="mb-3" controlId="formBasicText">
            <Form.Label><h6 style={capstyle}> Subscribe to our news letter for announcements and weekly events.</h6></Form.Label>
            <div className="news-flex"><Form.Control type="email" placeholder="Enter Email *" id="mce-EMAIL" className="email"  style={{ border: "transparent", borderRadius: "0px", height: "60px", display: "flex"  }} name="mail"   required/>
                <button type="submit" className="news-button" id="mail mc-embedded-subscribe " name="subscribe">SUBSCRIBE</button></div></Form.Group>
                </Form></div>
                
                
                
                
                
                </Container>
        </div>
    )
}

export default News
