import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import {  Container } from 'react-bootstrap';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Owlcarousel() {

    return (
        <Container>
            <div className='center-x ' >
        <OwlCarousel  loop margin={6} autoplay={true} autoplaySpeed={1500} nav={false}>
            
            
        <div className='img-margin'>
                <img  src="./assets/owl1.png" alt="neco"  />
            </div>
            <div className='img-margin'>
                <img  src="./assets/owl2.png" alt="google for education"  />
            </div>
            <div className='img-margin'>
                <img  src="./assets/owl3.png" alt="jamb"  />
            </div>
            <div className='img-margin'>
                <img  src="./assets/owl4.png" alt="waec"  />
            </div>
            <div className='img-margin'>
                <img  src="./assets/owl5.png" alt="SAT"  />
            </div>
        </OwlCarousel></div>
        </Container>
    )
        
}
export default Owlcarousel