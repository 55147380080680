import React, { useState }  from 'react';
import Barnav from "./Barnav";
import Footer from './Footer';
import Intro from './academics/Intro';
import Body from './academics/Body';
import Cards from './academics/Cards';
import News from './News';

function Academics() {
    const [active , setActive ] = useState("academics")
    return (
        
        <div>
              <Barnav activePage= {active} setActivePage={setActive}/>
        <div className="sections"> <Intro/>
        
         
         <div style={{ marginBottom:"60px"}}></div>
         <Body/>
         <div style={{ marginBottom:"60px"}}></div>
         <Cards/>
         
         <News/>
         <Footer/></div>
        </div>
    )
}

export default Academics
