import React ,{ useState}  from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Barnav from './Barnav';
import News from './News';
import Body from './boarding/Body';
import BoardingList from './boarding/BoardingList';
function Boarding() {
    const [active, setActive] = useState("boarding")
    return (
        <div>
<Barnav activePage= {active} setActivePage={setActive}/> 
<div className="sections">
             <Body/>
             <BoardingList/>
            <News/>
            <Footer/>
            
            </div>

        </div>
    )
}

export default Boarding
