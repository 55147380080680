import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import ScrollAnimation from 'react-animate-on-scroll';
import { Container } from 'react-bootstrap'


function Body() {
    return (
        <div>
            <img src="./assets/hall-bg.jpg" alt="" className="w-100" />
            <span className="deck"></span>
            <Container>
            <ScrollAnimation duration={1.5}
                    animateIn='zoomIn' initiallyVisible={true}
                    animateOnce={true}
                ><h1 className="sora-green">What do we have to offer?</h1></ScrollAnimation>
                <p>At k-Bols International School our boarding life style gives a 100% home from home experience, our students get to have and do all the healthy things that makes one feel at home.
                    from our cozy Hostels, to the cafteria, games court, lounge and more, we can say that we give the best boarding experience. 
                </p>

                <img src="./assets/hall1.jpg" alt="" className="w-100 boarding-img" />
                <ScrollAnimation duration={1.5}
                    animateIn='zoomIn' initiallyVisible={true}
                    animateOnce={true}
                ><h3 className="sora-green">Our General objective as a boarding school</h3></ScrollAnimation>
                <ul className="ul-width">
                <ScrollAnimation animateIn='bounceInLeft' delay={50} initiallyVisible={true} animateOnce={true}><li><b>CHALLENGING ACADEMICS</b>: In an environment full of students, there is always academic competition, each student is kept on their toes to strive harder and succeed.</li></ScrollAnimation>
                    <ScrollAnimation animateIn='bounceInLeft' delay={100} initiallyVisible={true} animateOnce={true}><li><b>PERSONAL GROWTH</b>: Living away from home and residing in our hostel provides invaluable opportunities for personal growth. Such as time management, work ethic and independence in an accelerated manner than in a public school. </li></ScrollAnimation>
                    <ScrollAnimation animateIn='bounceInLeft' delay={150} initiallyVisible={true} animateOnce={true}><li><b> CONDUCIVE LEARNING ATMOSPHERE</b>: K-Bols international School is a hub for learning. We offer ample space for students to stretch themselves and put their classroom learning into practice.</li></ScrollAnimation>
                    <ScrollAnimation animateIn='bounceInLeft' delay={200} initiallyVisible={true} animateOnce={true}><li><b>SOCIAL MATURITY</b>: As students settle down on campus they immediately notice they are going to be among a diverse group of peers -from different ethnicities, believes and behavioral patterns - which makes them to learn about team work, connection and conflicts. As a result, they're often more socially prepared and ready to embrace a world full of different people and contrasting points of view. </li></ScrollAnimation>
                </ul>
                <img src="./assets/hall1.png" alt="" className="w-100 boarding-img" />
                <ScrollAnimation duration={1.5}
                    animateIn='zoomIn' initiallyVisible={true}
                    animateOnce={true}
                > <h3 className="sora-green">Dorm, bathroom and convenience.</h3></ScrollAnimation>
                <p>It's best to start with the popular saying "cleanliness is next to Godliness". As a school of great standard, we pay a lot of attention to Personal hygiene and the cleanliness of every inch of our campus. K-Bols understands the importance of healthy living in everyday life. </p>
                <img src="./assets/hall2.jpg" alt="" className="w-100 boarding-img" />
            </Container>
            <img className="w-100" src="./assets/boarding.jpg" alt="" />
        </div>
    )
}

export default Body
