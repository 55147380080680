import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Col, Container, Row } from 'react-bootstrap';

function Header() {
    return (

        <div className="about-bg">

            <Container className="align-self-center" style={{  paddingTop: "auto", marginBottom: "auto" }}>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}><Row className="align-self-center" >
                    <Col >
                        <div className="intro-length poppins my-auto " style={{ color: "white" , paddingTop: "150px"}}>
                            <h4>Meet The Founder</h4>
                            <h3 className="poppins-bold" >
                                Chief Olukayode Akindele JP <br /> Bsc (Econs); FCA; FCTI. <br /></h3>
                                <p>
                                Founder and Chairman of School Governing Board.</p>

                        </div>
                    </Col>
                    <Col>
                        <img  src="./assets/chairman.png" className="w-100 img-414" alt="" />
                    </Col>
                </Row>
                </div>

            </Container>
        </div>
    )
}

export default Header
