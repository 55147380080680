import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Col, Container, Row} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

function Body() {
    return (
        <div>
            <ScrollAnimation duration={1.5}
                    animateIn='zoomIn'
                ><h1 className="sora-bold center-x" >A Quick Tour of our Campus</h1></ScrollAnimation>
                <video className="w-100 " src="./assets/tour.m4v" loop muted autoPlay></video>
            <Container>
            {/*<video className="w-100"   muted autoPlay loop controls>
      <source src="./assets/vid.mp4" type="video/mp4"/>
     </video><span className="deck"></span>*/}
                <Row  >
                    <Col style= {{ margin: "auto"}}>
                        <div className="intro-length" ><ScrollAnimation animateIn='fadeIn'
  ><h3 className="sora-green">OUR VISION</h3></ScrollAnimation>
                        <ScrollAnimation animateIn='bounceInLeft' duration={1.5}
  ><div className="content">
                                <p> <em>The Vision for the school is to create a happy
                                    Christian based environment aimed at raising responsible citizens through academic excellence. </em>
                                    K-Bols International School students as future
                                    leaders are guided and shaped by Christian values, critical thinking,
                                    high achievement, ethical principles and discipline, that will stand
                                    the test of time and enable them to excel in their chosen careers and make
                                    the world a better place.
                                </p>
                            </div></ScrollAnimation>
                        </div>
                    </Col>

                    <Col >
                    <ScrollAnimation animateIn='bounceInRight' duration={1}>
                            <img className="w-100 img-414" src="./assets/vision.png" width="360px" alt="" />
                        </ScrollAnimation>
                    </Col>

                </Row>
                <span className="deck" ></span>
                <Row >
                    <Col><ScrollAnimation animateIn='bounceInLeft' duration={1}>
                        <img className="w-100 img-414" src="./assets/mission.png" width="360px" alt="" /></ScrollAnimation>
                    </Col>

                    <Col style= {{ margin: "auto"}}>
                        <div className="intro-length" ><ScrollAnimation animateIn='fadeIn'
  ><h3 className="sora-green">MISSION STATEMENT</h3></ScrollAnimation>
                        <ScrollAnimation animateIn='bounceInRight' duration={1}><div className="content" >
                                <p> At K-Bols International School, we believe success is by grace.
                                    Our goal is to bring out the best in all students, irrespective of their
                                    backgrounds, faith or gender. This will be made possible through qualitative
                                    and effective tutoring and counselling in a bid to create well adjusted, socially
                                    aware and responsible citizens of the world.
                                </p>
                            </div></ScrollAnimation>
                        </div>
                    </Col>
                </Row>
                <span className="deck" ></span>
                <Row >
                    <Col>
                        <div className="intro-length" ><ScrollAnimation animateIn='fadeIn'
  ><h3 className="sora-green">OUR CORE VALUES</h3></ScrollAnimation>
                            <div className="content">
                                <ul>
                                <ScrollAnimation animateIn='bounceInLeft' delay={50}><li>Truthfulness</li></ScrollAnimation>
                                <ScrollAnimation animateIn='bounceInLeft' delay={100}><li>Creativity</li></ScrollAnimation>
                                    <ScrollAnimation animateIn='bounceInLeft' delay={150}><li>Self-reliance</li></ScrollAnimation>
                                    <ScrollAnimation animateIn='bounceInLeft' delay={200}><li>Team-work</li></ScrollAnimation>
                                   <ScrollAnimation animateIn='bounceInLeft' delay={250}><li>Discipline</li></ScrollAnimation>
                                </ul>
                            </div>
                        </div>
                    </Col>

                    <Col>
                    <ScrollAnimation animateIn='bounceInRight' duration={1}><img className="w-100 img-414" src="./assets/values.png" width="360px" alt="" />
                   </ScrollAnimation> </Col>
                </Row>
                <span className="deck" ></span>
                <Row>
                    <Col>
                        <div className="intro-length" ><ScrollAnimation animateIn='fadeIn'
  ><h3 className="sora-green">OUR MOTTO</h3></ScrollAnimation>
                            <div className="content">
                                <ul>
                                <ScrollAnimation animateIn='bounceInLeft' delay={50}><li>INTEGRITY</li></ScrollAnimation>
                                    <ScrollAnimation animateIn='bounceInLeft' delay={100}><li>FAITH</li></ScrollAnimation>
                                    <ScrollAnimation animateIn='bounceInLeft' delay={150}><li>EXCELLENCE</li></ScrollAnimation>

                                </ul>
                            </div>
                        </div>
                    </Col>

                    <Col>
                        <div className="intro-length" ><h3 className="sora-green">Frequently Asked Questions</h3> </div>
                        <Accordion className="intro-length" >
                            
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>When and how do I register for admission?</Accordion.Header>
                                    <Accordion.Body>
                                        The doors for registration are always open, for admission kindly <a href="/#admission"> click the link </a> to get started.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What are the security standards?</Accordion.Header>
                                    <Accordion.Body>
                                        We have a 24/7 CCTV surveillance ,
                                        a group of armed Police men and our security officers
                                        on all areas of our campus.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Do students write Foreign exams?</Accordion.Header>
                                    <Accordion.Body>
                                    Yes, we also prepare our students for International exams
                                        such as TOEFL, IGCSE, SAT, IELT and others.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion>
                    </Col>
                </Row>
                <span className="deck" ></span>
                <Row>
                    <Col>
                        <div className="mini-card">
                            <div className="mini-card-text" >
                                <h5 className="sora-green-nospace">Why Not Join Us Today?</h5>
                                <p>Click the button below to begin the admission process.</p>
                                <a href="/#admission"><button type="button">Admission</button></a>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="mini-card">
                            <div className="mini-card-text">
                                <h5 className="sora-green-nospace">For More Enquiries</h5>
                                <p>Kindly, send an email to <a href="mailto:kbolsltd@gmail.com">kbolsltd@gmail.com</a> or visit our <a href="/contact">Contact page</a> to book a date and visit us.</p>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Body
