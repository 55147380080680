import React from 'react'
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';

function Intro() {

    return (
        <div className="about-bg">
            <div style={{ color: "white", paddingTop: "60px", paddingBottom: "60px" }} >
                <h1 className="center-x"> Want to make enquiries?</h1>
                <h3 className="center-x">Send us a message!</h3>

                {/*ICONS*/}
                <div className="iconsss center-x" style={{ marginTop: "20px" }}>

                    <a href="https://www.facebook.com/KBolsInternationalSchool/" target="_blank" rel="noreferrer">
                        <FaFacebook size="25px" color="white" />
                    </a>
                    <a href="mailto:kbolsltd@gmail.com" target="_blank" rel="noreferrer" width="25px" height="25px">
                        <FiMail size="25px" color="white" />
                    </a>

                    <a href="https://www.instagram.com/kbolsinternational/" target="_blank" rel="noreferrer">
                        <FaInstagram size="25px" color="white" />
                    </a>


                </div>
            </div>

        </div>
    )
}

export default Intro
