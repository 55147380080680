import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel} from 'react-bootstrap';


function Slide() {
    const capstyle = {
        color: "white",
        padding: "10px",
        textAlign: "left",
        marginBottom: "150px",

    };
    const headerStyle = {
        fontSize: "24px",
        backgroundColor: "#EDEDED",
        width: "fit-content",
        color: "#A7A9AC",
        padding: "5px",
    };
    const textstyle = {
        textShadow: "2px 4px 6px black",
        fontSize: "35px",
        maxWidth: "640px",
        lineHeight: "1.1",
    };
    

    return (
        <div>
            <Carousel>
            <Carousel.Item >
                
            <img
            
                        className="d-block w-100"
                        src="./assets/admission23.jpg"
                        
                        alt="admission"
                    />
                    
                    </Carousel.Item>
                <Carousel.Item >
                    <img
                        className="d-block w-100"
                        src="./assets/slide1.jpg"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <div className="hide" style={capstyle}>
                            <h3 className= "poppins-medium" style={headerStyle}>BUILDING TOMMORROW'S LEADER TODAY</h3>
                            <p className= "poppins-medium" style={textstyle}>Giving Our <span style={{ color: "#ED7200" }}>Students</span> The Best
                               Facilities To Turn Their <span style={{ color: "#ED7200" }}>Dreams </span>
                            Into <span style={{ color: "#ED7200" }}>Reality</span>.</p>
                            <a href="#admission"><button href="">Apply Now</button></a><span style={{marginRight:"10px"}}></span><a href="/about"><button  className='btntransparentwhite'>Learn More</button></a></div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item >
                    <Carousel.Caption>
                        <div className="hide" style={capstyle}>
                                <h3 className="poppins-medium" style={headerStyle}>SERENE LEARNING ENVIROMENT</h3>
                                <p className="poppins-medium" style={textstyle}> Our spacious and  <span style={{ color: "#ED7200" }}>well equipped </span>
                                 class rooms help faciltate learning.
                                  </p>
                                <a href="/academics"><button  href="">Curriculum</button></a><span style={{ marginRight: "10px" }}></span><a href="/about"><button className='btntransparentwhite'>Learn More</button></a></div>
                    </Carousel.Caption>
                    <img
                        className="d-block w-100"
                        src="./assets/acad3.jpg"
                        alt="Science Lab"
                    />
                </Carousel.Item>
                <Carousel.Item >
                    <Carousel.Caption>
                        
                            <div className="hide" style={capstyle}>
                                <h3 className="poppins-medium" style={headerStyle}>EDUCATIVE EVENTS AND EXCURSIONS.</h3>
                                <p className="poppins-medium" style={textstyle}>We make <span style={{ color: "#ED7200" }}>Education</span> fun for our students. Nothing beats a well <span style={{ color: "#ED7200" }}>motivated </span>student.</p>
                                <a href="/#admission"><button  href="">Apply Now</button></a><span style={{ marginRight: "10px" }}></span><a href="/about"><button className='btntransparentwhite'>Learn More</button></a></div>
                        

                    </Carousel.Caption>
                    <img
                        className="d-block w-100"
                        src="./assets/acad2.jpg"
                        alt="Second slide"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Slide
