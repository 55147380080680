import React from 'react';
import { Container, Card } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

function Cards() {
    return (
        <div className="card-sec">
           <ScrollAnimation animateIn='fadeIn'
  ><Container className="deck" >
               <Card className="card"  style={{ width: '18rem', marginBottom: "15px" }}>
  <Card.Img variant="top" src="./assets/computer.jpg" />
  <Card.Body>
    <Card.Title>Computer Science</Card.Title>
    <Card.Text>
    Our goal is to provide a world class 
    computer science education to our students and giving them coding and other resourceful skills.
    </Card.Text>
   
  </Card.Body>
</Card>

<Card style={{ width: '18rem' , marginBottom: "15px" }}>
  <Card.Img variant="top" src="./assets/art.jpg" />
  <Card.Body>
    <Card.Title>Art</Card.Title>
    <Card.Text>
    Our
principal aim is to develop the children’s knowledge, skills and understanding in art and
design. We ensure that the act of investigating and making something includes exploring
and developing ideas, and evaluating and developing work.
    </Card.Text>
   
  </Card.Body>
</Card>

<Card style={{ width: '18rem' , marginBottom: "15px"}}>
  <Card.Img variant="top" src="./assets/chemistry.jpg" />
  <Card.Body>
    <Card.Title>Science</Card.Title>
    <Card.Text>
    The goal of science is to learn how nature works by observing the natural and physical world, and to understand this world through research and experimentation.
    </Card.Text>
   
  </Card.Body>
</Card>

<Card style={{ width: '18rem', marginBottom: "15px" }}>
  <Card.Img variant="top" src="./assets/library.jpg" />
  <Card.Body>
    <Card.Title>Library</Card.Title>
    <Card.Text>
    A well equipped Library thats not limited to just books but with access to do the internet for further research.
    </Card.Text>
   
  </Card.Body>
</Card>

<Card style={{ width: '18rem', marginBottom: "15px" }}>
  <Card.Img variant="top" src="./assets/music.jpg" />
  <Card.Body>
    <Card.Title>Music</Card.Title>
    <Card.Text>
    We facilitate and promote the overall development of the personality of our students. We promote interest in and motivation for music and music related activities.
    </Card.Text>
   
  </Card.Body>
</Card>


<Card style={{ width: '18rem', marginBottom: "15px" }}>
  <Card.Img variant="top" src="./assets/sc16.jpg" />
  <Card.Body>
    <Card.Title>Sports</Card.Title>
    <Card.Text>
    All students are trained in various sports and should be able to play atleast two sports proffesional before graduation. K-Bols provides a vast number of sports  including soccer, basketball, Lawn Tennis, volleyball, swimming but to mention a few.
    </Card.Text>
   
  </Card.Body>
</Card>


<Card style={{ width: '18rem', marginBottom: "15px" }}>
  <Card.Img variant="top" src="./assets/accounting.jpg" />
  <Card.Body>
    <Card.Title>Accounting</Card.Title>
    <Card.Text>
    The aim of accounting education is to help our students learn to become professional accountants and finance gurus in their various fields.
    </Card.Text>
   
  </Card.Body>
</Card>




</Container></ScrollAnimation>
        </div>
    )
}

export default Cards
