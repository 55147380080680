import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Barnav from "./Barnav";
import Footer from './Footer';
import Intro from './about/Intro';
import Body from './about/Body';
import News from './News';
function About() {
    const [active, setActive] = useState("about")
    return (
        <div>
            <Barnav activePage={active} setActivePage={setActive} />
            <div className="sections">
                <Intro />
                <div style={{ marginBottom: "60px" }}></div>
                <Body />
                <div style={{ marginBottom: "60px" }}></div>
                <News/>
                <Footer />
            </div>
        </div>
    )
}

export default About;
