import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
function Barnav({ activePage, setActivePage }) {
  function isActive(page) {
    if (page === activePage) {
      return { color: "#0DA40D", fontWeight: "bold" }
    }
  }
  const shadow = {
    boxShadow: "0px 0px 10px #c4c4c4"
  }
  return (

    <div>

      <Navbar className="fixed-top" collapseOnSelect expand="lg" bg="light" style={shadow}>
        <Container>

          <Navbar.Brand href="#home">

            <img className="w-100 logo" src="./assets/logo.png" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">

            <Nav className="me-auto">
            </Nav>
            <Nav className="poppins-medium"  >
              <Nav.Link href="/" style={isActive("home")} onClick={() => setActivePage("home")}>HOME</Nav.Link>
                           
              <NavDropdown  title="ABOUT US" id="basic-nav-dropdown" >
                            <NavDropdown.Item href="/about-founder" exact  style={isActive("about-founder")} onClick={() => setActivePage("about-founder")}>Our Founder</NavDropdown.Item>
                            <NavDropdown.Item  href="/about" >Our Co-founder</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/academics" style={isActive("academics")} onClick={() => setActivePage("academics")}>ACADEMICS</Nav.Link>
              <Nav.Link href="/boarding" style={isActive("boarding")} onClick={() => setActivePage("boarding")}>BOARDING LIFE</Nav.Link>
               <Nav.Link href="/gallery" style={isActive("gallery")} onClick={() => setActivePage("gallery")}>GALLERY</Nav.Link>
               <Nav.Link href="/contact" style={isActive("contact")} onClick={() => setActivePage("contact")}>CONTACT</Nav.Link>
             

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      
    </div>
  )
}

export default Barnav;
